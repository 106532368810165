import { keys } from "lodash"

const names = new Map()
names.set("FNAME", "First name")
names.set("LNAME", "Last name")
names.set("MCOMPANY", "Company name")
names.set("EMAIL", "Email")

const parseMessage = (message = "", name = "") => {
  if (message.includes("is a required field")) {
    return `${names.get(name)} is required`
  }

  if (message.includes("must be a valid email")) {
    return `${names.get(name)} should be a valid email format`
  }
  return message
}

const getErrorsMessage = (errors) => {
  let parsedErrors = {}
  keys(errors).forEach((key) => {
    const error = errors[key]
    parsedErrors = {
      ...parsedErrors,
      [key]: {
        ...error,
        message: parseMessage(error.message, key),
      },
    }
  })
  return parsedErrors
}

export default getErrorsMessage
