import React from "react"
import styled, { th, breakpoints, css, x } from "@xstyled/styled-components"
import { Title, Text } from "@atoms/Typography"
import Container from "@atoms/Container"
import MailChimpRegister from "@molecules/MailChimpRegister"

function Cta() {
  return (
    <S.Section>
      <S.Container>
        <S.Title as="h1" variant="h1">
          Sign up to get 10% off your first hire.
        </S.Title>
        <Text>Offer only valid for new members</Text>
        <x.div justifyContent="center">
          <MailChimpRegister />
        </x.div>
      </S.Container>
    </S.Section>
  )
}

const S = {}

S.Section = styled.section`
  padding-top: 90;
  padding-bottom: 90;
  background: ${th("colors.laserYellow")};
`

S.Container = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

S.Title = styled(Title)`
  text-align: center;
  ${breakpoints({
    _: css`
      margin-bottom: 40;
    `,
    md: css`
      margin-bottom: 60;
      max-width: 625;
    `,
    xl: css`
      margin-bottom: 60;
      max-width: 857;
    `,
  })}
`

export default Cta
