import React from "react"
import "./style.css"

const Toggle = ({ id = "toggle", toggled, onChange = () => {} }) => {
  return (
    <>
      <label htmlFor={`switch${id}`} className="switch">
        <input
          type="checkbox"
          onChange={onChange}
          checked={toggled}
          id={`switch${id}`}
        />
        <span className="slider round" />
      </label>
    </>
  )
}

export default Toggle
