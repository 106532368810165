import React from "react"
import PropTypes from "prop-types"
import styled, { x } from "@xstyled/styled-components"
import { Title, Text } from "@atoms/Typography"
import Image from "@atoms/Image"
import ButtonList from "@molecules/ButtonList"

const TextImage = ({
  color,
  order,
  title,
  paragraphs,
  image,
  button,
  ...props
}) => {
  return (
    <x.div row alignItems="center" justifyContent="space-between" {...props}>
      <x.div
        col={{ _: 1, md: 1 / 2 }}
        order={{ _: 2, md: order === "1" ? 1 : 2 }}
        maxWidth={{
          _: "full",
          lg: 425,
          xl: 460,
        }}
      >
        {title && (
          <Title
            color={color}
            as="h3"
            variant="h3"
            mb={{ _: 30, lg: 22, xl: 30 }}
          >
            {title}
          </Title>
        )}
        {paragraphs && (
          <Text color={color} variant="sm" lineHeight="1.5">
            {paragraphs}
          </Text>
        )}

        {button && <ButtonList items={[button]} />}
      </x.div>

      <x.div
        col={{ _: 1, md: 1 / 2 }}
        order={{ _: 1, md: order === "1" ? 2 : 1 }}
        maxWidth={{
          _: "full",
          lg: 420,
          xl: 550,
        }}
        mb={{ _: 25, lg: 0 }}
      >
        <S.Img image={image} style={{ width: "100%" }} />
      </x.div>
    </x.div>
  )
}

const S = {}

S.Img = styled(Image)`
  max-width: 485px;
`

TextImage.propTypes = {
  title: PropTypes.string,
  paragraphs: PropTypes.string,
  order: PropTypes.string,
}

TextImage.defaultProps = {
  title: null,
  paragraphs: null,
  order: "1",
}

export default TextImage
