import React from "react"
import PropTypes from "prop-types"
import styled from "@xstyled/styled-components"
import SemiCirclePink from "./semicircle-pink.svg"
import TriangleTop from "./hero-svg/home/triangle-top.svg"
import SemiCircle from "./hero-svg/home/semicircle-purple.svg"
import LoopLine from "./hero-svg/home/loop-line.svg"
import SemiLine from "./semi-line.svg"
import SemiLineTablet from "./semi-line-tablet.svg"
import SemiLineMobile from "./semi-line-mobile.svg"
import SemiLineRight from "./semi-line-right.svg"
import SemiLineRightTablet from "./semi-line-right-tablet.svg"
import SemiLineRightMobile from "./semi-line-right-mobile.svg"
import SemiLineBottom from "./semi-line-bottom.svg"
import SemiLineFullPageLeft from "./semi-line-full-page-left.svg"
import SemiLineRoundLeft from "./sem-line-round-left.svg"
import SemiLineRoundLeftMobile from "./sem-line-round-left-mobile.svg"
import SemiLineRoundLeftInverted from "./sem-line-round-left-inverted.svg"
import SemiLineBottomLeftWavy from "./semi-line-bottom-left-wavy.svg"
import SemiLineBottomLeftWavyMobile from "./semi-line-bottom-left-wavy-mobile.svg"
import SemiLineCrossLeft from "./semi-line-cross-left.svg"
import SemiLineCrossLeftTablet from "./semi-line-cross-left-tablet.svg"
import RectangleRotatedCenteredBlue from "./rectangle-rotated-centered-blue.svg"
import RotatedLeafGreen from "./rotated-leaf-green.svg"
import TriangleGreen from "./triangle-green.svg"
import CirclePink from "./circle-pink.svg"
import CirclePinkBig from "./hero-svg/home/circle-pink.svg"
import CircleHalfPink from "./circle-half-pink.svg"
import CircleHalfInvertedBlue from "./circle-half-inverted-blue.svg"
import CircleHalfInvertedBlueTablet from "./circle-half-inverted-blue-tablet.svg"
import CircleCroppedBlue from "./circle-cropped-blue.svg"
import CornerCircleGreen from "./corner-circle-green.svg"
import CornerCircleGreenMobile from "./corner-circle-green-mobile.svg"
import TopLeftCircleBlue from "./top-left-circle-blue.svg"
import TopLeftCircleBlueMobile from "./top-left-circle-blue-mobile.svg"
import TopLeftCircleBlueTablet from "./top-left-circle-blue-tablet.svg"
import TopRightCroppedCircleBlue from "./top-right-cropped-circle-blue.svg"
import TopRightCroppedRotatedCircleBlue from "./top-right-cropped-rotated-circle-blue.svg"
import HomeMobileSemiline from "./hero-svg/home/home-semiline-mobile.svg"
import YellowSmile from "./hero-svg/home/yellow-smile.svg"
import SemiBoxBlue from "./hero-svg/home/semibox-blue.svg"
import HIWHeroLine from "./hero-svg/how-it-works/line.svg"
import HIWHeroLineMobile from "./hero-svg/how-it-works/line-mobile.svg"
import HIWHeroSemiCircle from "./hero-svg/how-it-works/semicircle.svg"
import IFYHeroSemiCircle from "./hero-svg/imabi-for-you/blue-semicircle.svg"
import IFYHeroGreenBox from "./hero-svg/imabi-for-you/green-box.svg"
import IFYHeroLineMobile from "./hero-svg/imabi-for-you/line-mobile.svg"
import IFYHeroLine from "./hero-svg/imabi-for-you/line.svg"
import IFYHeroPinkCircle from "./hero-svg/imabi-for-you/pink-circle.svg"
import IFBHeroBlueSemiCircle from "./hero-svg/imabi-for-business/blue-semicircle.svg"
import IFBHeroDoubleCircleMobile from "./hero-svg/imabi-for-business/double-circle-mobile.svg"
import IFBHeroDoubleCircle from "./hero-svg/imabi-for-business/double-circle.svg"
import IFBHeroGreenLeaf from "./hero-svg/imabi-for-business/green-leaf.svg"
import IFBHeroLine from "./hero-svg/imabi-for-business/line.svg"
import IFBHeroPinkCircle from "./hero-svg/imabi-for-business/pink-circle.svg"
import ABTHeroGreenTriangle from "./hero-svg/about/green-triangle.svg"
import ABTHeroLine from "./hero-svg/about/line.svg"
import OrgangeLine from "./orange-line.svg"
import QuarterCircle from "./quarter-circle.svg"
import HOMELine from "./home-section-line.svg"
import HOMEGreenSemiCircle from "./green-semicircle.svg"
import HOMEGreenLeaf from "./green-leaf.svg"
import HOMEGreenBox from "./green-box.svg"
import HOMEDarkgreenSemiCircle from "./darkgreen-semicircle.svg"
import HOMEBlueSemiCircleMobile from "./blue-semicircle-mobile.svg"

const svgs = {
  TriangleTop,
  SemiCircle,
  LoopLine,
  SemiLine,
  SemiLineTablet,
  SemiLineMobile,
  SemiLineRight,
  SemiLineRightMobile,
  SemiLineRightTablet,
  SemiLineBottom,
  SemiLineFullPageLeft,
  SemiLineRoundLeft,
  SemiLineRoundLeftMobile,
  SemiLineRoundLeftInverted,
  SemiLineBottomLeftWavy,
  SemiLineBottomLeftWavyMobile,
  SemiLineCrossLeft,
  SemiLineCrossLeftTablet,
  RectangleRotatedCenteredBlue,
  RotatedLeafGreen,
  TriangleGreen,
  CirclePink,
  CirclePinkBig,
  CircleHalfPink,
  CircleHalfInvertedBlue,
  CircleHalfInvertedBlueTablet,
  CircleCroppedBlue,
  CornerCircleGreen,
  CornerCircleGreenMobile,
  TopLeftCircleBlue,
  TopLeftCircleBlueMobile,
  TopLeftCircleBlueTablet,
  TopRightCroppedCircleBlue,
  TopRightCroppedRotatedCircleBlue,
  SemiCirclePink,
  HomeMobileSemiline,
  SemiBoxBlue,
  HIWHeroLine,
  HIWHeroLineMobile,
  HIWHeroSemiCircle,
  IFYHeroSemiCircle,
  IFYHeroGreenBox,
  IFYHeroLineMobile,
  IFYHeroLine,
  IFYHeroPinkCircle,
  IFBHeroBlueSemiCircle,
  IFBHeroDoubleCircleMobile,
  IFBHeroDoubleCircle,
  IFBHeroGreenLeaf,
  IFBHeroLine,
  IFBHeroPinkCircle,
  ABTHeroGreenTriangle,
  ABTHeroLine,
  OrgangeLine,
  QuarterCircle,
  HOMELine,
  HOMEGreenSemiCircle,
  HOMEGreenLeaf,
  HOMEGreenBox,
  HOMEDarkgreenSemiCircle,
  HOMEBlueSemiCircleMobile,
  YellowSmile,
}

const Pattern = ({ children, ...props }) => {
  return <S.PatternWrapper {...props} />
}

const S = {}

S.PatternWrapper = styled.divBox`
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  pointer-events: none;
  z-index: 0;
  background-image: url(${(props) => svgs[props.svg]});
`

Pattern.propTypes = {
  children: PropTypes.string,
}

Pattern.defaultProps = {
  children: null,
}

export default Pattern
