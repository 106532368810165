import React from "react"
import PropTypes from "prop-types"
import { x } from "@xstyled/styled-components"
import Container from "@atoms/Container"
import NavigationSpacer from "../NavigationSpacer"

const PageHero = ({
  smallTitle,
  title,
  text,
  image,
  form,
  patterns,
  ...props
}) => {
  return (
    <x.section pt={{ _: "s30", lg: "s75" }} position="relative" {...props}>
      <NavigationSpacer />
      <Container>
        <x.div row>
          <x.div
            position="relative"
            col={{ _: 1, md: 2 / 3 }}
            order={{ _: 2, md: 1 }}
            pb={{ _: 86, lg: 100, xl: 147 }}
          >
            {patterns}
            {smallTitle && smallTitle}
            {title}
            {text}
            {form}
          </x.div>
          <x.div
            display="flex"
            justifyContent="flex-end"
            col={{ _: 1, md: 1 / 3 }}
            order={{ _: 1, md: 2 }}
            w="100%"
          >
            {image}
          </x.div>
        </x.div>
      </Container>
    </x.section>
  )
}

PageHero.propTypes = {
  patterns: PropTypes.element,
  smallTitle: PropTypes.element,
  title: PropTypes.element,
  text: PropTypes.element,
  image: PropTypes.element,
  form: PropTypes.element,
}

PageHero.defaultProps = {
  patterns: null,
  smallTitle: null,
  title: null,
  text: null,
  image: null,
  form: null,
}

export default PageHero
