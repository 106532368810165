import React, { useState } from "react"
import PropTypes from "prop-types"

import { FormProvider } from "react-hook-form"
import { x } from "@xstyled/styled-components"
import ErrorMessage from "@atoms/ErrorMessage"
import SuccessMessage from "@atoms/SuccessMessage"

const MailChimpForm = ({ action, children, methods, ...props }) => {
  const [status, setStatus] = useState(null)
  const [message, setMessage] = useState(null)

  const onSubmit = async (data) => {
    if (!window.drift) {
      setStatus("error")
      setMessage("We could not save your email at this moment")
    }
    window?.drift?.api.setUserAttributes({
      email: data.EMAIL,
    })

    drift.api.startInteraction({
      interactionId: 2277972,
      goToConversation: true,
    })

    setStatus("success")
    setMessage("We'll be in contact")
  }

  return (
    <FormProvider {...methods}>
      <x.form onSubmit={methods.handleSubmit(onSubmit)} noValidate {...props}>
        {status === "success" ? null : children}
        {message && (
          <x.div mt="s20">
            {status === "error" && <ErrorMessage>{message}</ErrorMessage>}
            {status === "success" && <SuccessMessage>{message}</SuccessMessage>}
          </x.div>
        )}
      </x.form>
    </FormProvider>
  )
}

MailChimpForm.propTypes = {
  action: PropTypes.string.isRequired,
}

MailChimpForm.defaultProps = {}

export default MailChimpForm
