import React from "react"
import { ErrorMessage as FormErrorMessage } from "@hookform/error-message"
import LabelErrorMessage from "@atoms/ErrorMessage"

const ErrorMessageField = ({ errors, name, ...props }) => {
  return (
    <FormErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <LabelErrorMessage name={name} color="neutral.red" {...props}>
          {message}
        </LabelErrorMessage>
      )}
    />
  )
}

export default ErrorMessageField
