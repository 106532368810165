import React from "react"
import { x } from "@xstyled/styled-components"
import Container from "@atoms/Container"
import FeatureCallOut from "@molecules/FeatureCallOut"
import Pricing from "@assets/images/home/pricing.svg"
import Matching from "@assets/images/home/matching.svg"
import Vetting from "@assets/images/home/vetting.svg"

const Features = () => {
  const title = "Find top remote talent. Without the headache."
  const items = [
    {
      title: "Transparent pricing",
      paragraphs:
        "See rates up front and only pay for time delivered. No hidden fees or confusing commissions.",
      image: Pricing,
    },
    {
      title: "48 hour matching",
      paragraphs:
        "Tell about your project or hiring needs and we’ll match you with the talent to build the team you need.",
      image: Matching,
    },
    {
      title: "Strict vetting",
      paragraphs:
        "Finding good tech talent is hard. We apply Silicon Valley-level vetting to everyone we work with.",
      image: Vetting,
    },
  ]
  return (
    <x.section bg="neutral.grey.100">
      <Container>
        <FeatureCallOut
          title={title}
          items={items}
          pb={{ _: 52, lg: 120, xl: 145 }}
          paragraphsProps={{
            maxWidth: { _: "full", lg: 246, xl: 324 },
          }}
        />
      </Container>
    </x.section>
  )
}

export default Features
