import React from "react"
import styled, { x, breakpoints, css } from "@xstyled/styled-components"
import Button from "@atoms/Button"
import { Title, Text } from "@atoms/Typography"
import PageHero from "@atoms/PageHero"
import Image from "@atoms/Image"
import Pattern from "@molecules/Pattern"

import HelloWorld from "@atoms/HelloWorld"

const Hero = () => {
  return (
    <PageHero
      bg="neutral.grey.200"
      pb={{ lg: "s150" }}
      patterns={
        <>
          <Pattern
            position="absolute"
            zIndex="1"
            svg="YellowSmile"
            top={{ _: 0, md: "-45px" }}
            left={{ lg: "52px", xl: "140px" }}
            w={{ _: "0px", lg: "0px", xl: "113px" }}
            h={{ _: "0px", lg: "0px", xl: "113px" }}
            backgroundSize="contain"
          />
        </>
      }
      title={
        <Title
          as="h1"
          variant="h1"
          mb={{ _: "s45", lg: "s30" }}
          maxWidth={{ _: "full", sm: "full", lg: "450px", xl: "830px" }}
          letterSpacing={{ lg: "-0.1px" }}
          fontSize={{ md: "52px", xl: "87px" }}
          textAlign="initial"
        >
          Remote hiring. Made easy.
        </Title>
      }
      text={
        <Text
          as="p"
          variant="lg"
          mb={{ _: "s45", lg: "s44", xl: "s70" }}
          maxWidth={{ _: "full", sm: "full", lg: "450px", xl: "540px" }}
          textAlign="initial"
          fontSize={{ _: 18, xl: 20 }}
        >
          Hire a remote technical team in days, not weeks. We pre-vet all talent
          and match you with the right devs and designers for your project. Hire
          into your team or build an external team managed by our expert PMs.
        </Text>
      }
      image={<HelloWorld />}
      form={
        <x.div
          maxWidth={{ _: "full", lg: "initial" }}
          display="flex"
          flexDirection={{ _: "column", lg: "row" }}
        >
          <x.div mb={{ _: "s15", lg: 0 }} mr={{ lg: "s20" }} w={{ lg: "s180" }}>
            <Button
              id="start-hiring"
              buttonMode="LINK"
              className="drift-open-chat"
            >
              Start hiring
            </Button>
          </x.div>
        </x.div>
      }
    />
  )
}

const S = {}

S.FullImageContainer = styled.divBox`
  width: 100%;
  ${breakpoints({
    _: css`
      max-width: 271;
    `,
    md: css`
      max-width: 380;
    `,
    lg: css`
      position: absolute;
      max-width: 360;
      left: 20;
      top: -30;
    `,
    xl: css`
      top: 0;
      max-width: 490;
    `,
  })}
`

export default Hero
