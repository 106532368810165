import React from "react"
import styled, { breakpoints, css } from "@xstyled/styled-components"
import Input from "@atoms/Input"
import Button from "@atoms/Button"

const InputAction = ({
  register,
  error,
  inputProps,
  actionProps,
  ...props
}) => {
  return (
    <S.Container {...props}>
      <S.Input register={register} error={error} {...inputProps} />
      <Button w={{ _: "100%", md: "auto" }} variant="orange" {...actionProps} />
    </S.Container>
  )
}

const S = {}

S.Container = styled.divBox`
  display: flex;
  align-items: center;

  ${breakpoints({
    _: css`
      flex-direction: column;
    `,
    md: css`
      flex-direction: row;
    `,
  })}
`

S.Input = styled(Input)`
  ${breakpoints({
    _: css`
      margin-bottom: 20;
    `,
    md: css`
      margin-right: 11;
      margin-bottom: 0;
    `,
  })}
`

export default InputAction
