/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import styled, { x } from "@xstyled/styled-components"
import { Title, Text } from "@atoms/Typography"

const FeatureCallOutItem = ({
  title,
  titleItemsProps,
  paragraphs,
  paragraphsProps,
  image,
  ...props
}) => {
  return (
    <x.li display="flex" flexDirection="column" alignItems="left" {...props}>
      {image ? <S.Icon url={image} mb={{ _: 0, lg: 10 }} /> : null}
      <Title
        textAlign="initial"
        as="h4"
        variant="h4"
        fontWeight="bold"        
        fontSize={{_: 20, md: 22}}
        lineHeight="1.5"
        mb={{ _: 20, lg: 23, xl: 20 }}
        {...titleItemsProps}
      >
        {title}
      </Title>
      <Text
        textAlign="initial"
        variant="lg"
        fontSize={{_: 18, lg: 20}}
        lineHeight="1.5"
        h={{ _: "initial", lg: 88 }}
        maxWidth={{ _: 303, lg: 246, xl: 263 }}
        {...paragraphsProps}
      >
        {paragraphs}
      </Text>
    </x.li>
  )
}

const S = {}

S.Icon = styled.divBox`
  background: url(${(props) => props.url}) no-repeat top left;
  background-size: 160px 160px;
  width: 160px;
  height: 160px;
`

FeatureCallOutItem.propTypes = {
  title: PropTypes.string,
  titleItemsProps: PropTypes.object,
  paragraphs: PropTypes.string,
  paragraphsProps: PropTypes.object,
  image: PropTypes.string
}

FeatureCallOutItem.defaultProps = {
  title: null,
  titleItemsProps: {},
  paragraphs: null,
  paragraphsProps: {},
  image: null,
}

export default FeatureCallOutItem
