import React from "react"
import styled, { breakpoints, css } from "@xstyled/styled-components"
import { Title, textCss } from "@atoms/Typography"
import Container from "@atoms/Container"
import Button from "@atoms/Button"
import peaceImg from "@assets/images/home/peace.png"
import smileImg from "@assets/images/home/smile.png"
import pinkVector from "@assets/images/home/pink-vector.png"
import greenVector from "@assets/images/home/green-vector.png"

function Pricing() {
  return (
    <S.Section id="for-talent">
      <S.Container>
        <S.Title as="h2" variant="h2">
          Transparent rates, quality assured.
        </S.Title>

        <S.CardsContainer pt={{ _: 47, md: 60, xl: 80 }}>
          <S.CardPink mr={{ _: 0, md: 24, xl: 40 }}>
            <S.ImageContainer>
              <S.Image src={peaceImg} alt="Developer pricing" />
            </S.ImageContainer>

            <S.Title as="h3" variant="h3">
              Developer
            </S.Title>
            <S.Title as="h3" variant="h4">
              $35 to $70/h
            </S.Title>

            <S.List>
              <S.ListItem>Top-notch devs</S.ListItem>
              <S.ListItem>Custom matching in 48 hours</S.ListItem>
              <S.ListItem>Online technical support</S.ListItem>
              <S.ListItem>Full-time and part-time available</S.ListItem>
              <S.ListItem>Time tracking</S.ListItem>
            </S.List>

            <S.Button
              id="hire-developers"
              variant="invert"
              className="drift-open-chat"
            >
              Hire developers
            </S.Button>
          </S.CardPink>
          <S.Card>
            <S.ImageContainer>
              <S.Image src={smileImg} alt="Designer pricing" />
            </S.ImageContainer>

            <S.Title as="h3" variant="h3">
              Designer
            </S.Title>
            <S.Title as="h3" variant="h4">
              $25 to $50/h
            </S.Title>

            <S.List>
              <S.ListItem>Top-notch designers</S.ListItem>
              <S.ListItem>Custom matching in 48 hours</S.ListItem>
              <S.ListItem>Online technical support</S.ListItem>
              <S.ListItem>Full-time and part-time available</S.ListItem>
              <S.ListItem>Time tracking</S.ListItem>
            </S.List>

            <S.Button
              id="hire-developers"
              variant="invert"
              className="drift-open-chat"
            >
              Hire designers
            </S.Button>
          </S.Card>
        </S.CardsContainer>
      </S.Container>
    </S.Section>
  )
}

const S = {}

S.ImageContainer = styled.divBox`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

S.Image = styled.imgBox`
  width: 135;
  height: auto;
`

S.Button = styled(Button)`
  ${breakpoints({
    _: css`
      margin-top: 40;
    `,
    md: css`
      margin-top: 48;
    `,
    xl: css`
      margin-top: 60;
    `,
  })}
`

S.List = styled.ulBox`
  list-style-type: disc;
  ${breakpoints({
    _: css`
      padding-left: 20;
      padding-top: 35;
    `,
    md: css`
      padding-top: 35;
    `,
    xl: css`
      padding-top: 40;
      padding-left: 60;
    `,
  })}
`
S.ListItem = styled.liBox`
  ${textCss}
  font-size: 18px;
  ${breakpoints({
    _: css`
      padding-top: 20;
    `,
    xl: css`
      padding-top: 15;
    `,
  })}
`

S.Card = styled.divBox`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: url(${greenVector});

  ${breakpoints({
    _: css`
      padding-top: 40;
      padding-bottom: 40;
      padding-left: 20;
      padding-right: 20;
      width: 100%;
    `,
    md: css`
      padding-top: 45;
      padding-bottom: 70;
      padding-left: 20;
      padding-right: 20;
      width: 335;
    `,
    xl: css`
      padding-top: 50;
      padding-bottom: 70;
      padding-left: 60;
      padding-right: 60;
      width: 441;
    `,
  })}
`

S.CardPink = styled.divBox`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: url(${pinkVector});

  ${breakpoints({
    _: css`
      padding-top: 40;
      padding-bottom: 40;
      padding-left: 20;
      padding-right: 20;
      width: 100%;
    `,
    md: css`
      padding-top: 45;
      padding-bottom: 70;
      padding-left: 20;
      padding-right: 20;
      width: 335;
    `,
    xl: css`
      padding-top: 50;
      padding-bottom: 70;
      padding-left: 60;
      padding-right: 60;
      width: 441;
    `,
  })}
`

S.Section = styled.section`
  padding-top: 110;
  padding-bottom: 110;
`

S.Container = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

S.CardsContainer = styled.divBox`
  display: flex;
  ${breakpoints({
    _: css`
      flex-direction: column;
    `,
    md: css`
      flex-direction: row;
    `,
  })}
`

S.Title = styled(Title)`
  text-align: center;
`

export default Pricing
