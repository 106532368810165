import React, { useState, useEffect } from "react"

import styled, { x } from "@xstyled/styled-components"

import Container from "@atoms/Container"
import { Text } from "@atoms/Typography/index"

import Card from "@atoms/Card/index"
import Toggle from "@molecules/Toggle/index"

function DevCard({ id, dev, sum, sub }) {
  const [isToggled, setIsToggled] = useState(false)
  const [wasToggled, setWasToggled] = useState(false)

  useEffect(() => {
    if (isToggled || wasToggled) {
      if (!wasToggled) setWasToggled(true)
      if (isToggled) sum(dev.rate * 7.5)
      else sub(dev.rate * 7.5)
    }
  }, [isToggled])

  return (
    <x.div padding="15px" col={{ xs: 1, lg: 1 / 2, xl: 1 / 3 }}>
      <Card height="125" margin="0" p="0">
        <x.div display="flex" flexDirection="row" flex="1" flexGrow="1">
          <S.Container marginLeft="15px" padding="0px">
            <S.Photo src={dev.img} alt={dev.name} />
            <Text>
              <Text fontSize="15px" fontWeight="bold" as="span">
                ${dev.rate}
              </Text>
              /hr
            </Text>
          </S.Container>
          <x.div display="flex" margin="10px" flex="1" flexDirection="column">
            <x.div
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <x.div display="flex" flexDirection="column">
                <Text>{dev.name}</Text>

                <Text
                  lineHeight="19px"
                  fontSize="18px"
                  fontWeight="bold"
                  as="span"
                >
                  {dev.role}
                </Text>
                <Text>{dev.location}</Text>
              </x.div>
              <Toggle
                id={id}
                toggled={isToggled}
                onChange={(e) => setIsToggled(e.target.checked)}
              />
            </x.div>
            <x.div display="flex" flexDirection="row" marginTop="10px">
              {dev.techs.map((tech) => (
                <Card
                  borderRadius="4px"
                  color="#A09292"
                  background="#EEEBEB"
                  height="20px"
                  padding="5px 10px"
                  margin="0px 6px 0px 0px"
                  key={tech + id}
                >
                  <Text color="#A09292">{tech}</Text>
                </Card>
              ))}
            </x.div>
          </x.div>
        </x.div>
      </Card>
    </x.div>
  )
}

const S = {}

S.Container = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

S.Photo = styled.img`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  object-fit: cover;
`

export default DevCard
