import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import styled, { breakpoints, css, x } from "@xstyled/styled-components"
import { Title } from "@atoms/Typography"
import Container from "@atoms/Container"
import Button from "@atoms/Button"
import BottomFrame from "@assets/images/home/bottom-frame.png"
import QuestionAnswer from "@molecules/QuestionAnswer/index"
import idgen from "@utils/idgen"

function FAQ() {
  const data = useStaticQuery(graphql`
    query FAQQuery {
      markdownRemark(frontmatter: { collection: { eq: "faqs" } }) {
        frontmatter {
          collection
          blocks {
            type
            title
            paragraph
          }
        }
      }
    }
  `)

  return (
    <S.Section id="faqs">
      <S.Container>
        <S.Title as="h2" variant="h2">
          FAQ
        </S.Title>
        <x.div>
          {data.markdownRemark.frontmatter.blocks.map((item) => (
            <QuestionAnswer data={item} key={idgen()} />
          ))}
        </x.div>

        <x.div pt="50px">
          <x.div display="flex" justifyContent="center">
            <Button
              id="ask-question"
              variant="invert"
              className="drift-open-chat"
            >
              Ask a question
            </Button>
          </x.div>
        </x.div>
      </S.Container>
    </S.Section>
  )
}

const S = {}

S.Section = styled.section`
  padding-top: 90;
  padding-bottom: 90;
  background: url(${BottomFrame});
`

S.Container = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

S.Title = styled(Title)`
  text-align: center;
  ${breakpoints({
    _: css`
      margin-bottom: 40;
    `,
    md: css`
      margin-bottom: 60;
      max-width: 625;
    `,
    xl: css`
      margin-bottom: 60;
      max-width: 857;
    `,
  })}
`

export default FAQ
