import Img1 from "@assets/images/home/people/01.png"
import Img2 from "@assets/images/home/people/02.png"
import Img3 from "@assets/images/home/people/03.png"
import Img4 from "@assets/images/home/people/04.png"
import Img5 from "@assets/images/home/people/05.png"
import Img6 from "@assets/images/home/people/06.png"
import Img7 from "@assets/images/home/people/07.png"
import Img8 from "@assets/images/home/people/08.png"

export default [
  {
    name: "Cicero Guimarães",
    rate: 60,
    role: "Full Stack Dev",
    location: "Sao Paulo, Brazil",
    techs: ["Javascript", "React", "Python"],
    img: Img1,
  },
  {
    name: "Cauã Alves",
    rate: 50,
    role: "Backend Dev",
    location: "Rio de Janeiro, Brazil",
    techs: ["NodeJS", "MongoDB", "Python"],
    img: Img2,
  },
  {
    name: "Alberto Quadros",
    rate: 40,
    role: "Frontend Dev",
    location: "Floripa, Brazil",
    techs: ["Javascript", "React", "Angular"],
    img: Img3,
  },
  {
    name: "Henrique Garcia",
    rate: 60,
    role: "Mobile Dev",
    location: "Rio de Janeiro, Brazil",
    techs: ["Swift", "React", "Android"],
    img: Img4,
  },
  {
    name: "Tiago Delchiaro",
    rate: 40,
    role: "Web Dev",
    location: "Sao Paulo, Brazil",
    techs: ["Wordpress", "PHP", "HTML"],
    img: Img5,
  },
  {
    name: "Fabricio Leal",
    rate: 70,
    role: "DevOps Dev",
    location: "Rio de Janeiro, Brazil",
    techs: ["Kubernetes", "Docker", "Python"],
    img: Img6,
  },
  {
    name: "Maria Pessoa",
    rate: 30,
    role: "Product Designer",
    location: "Sao Paulo, Brazil",
    techs: ["Prototyping", "UX/UI", "Figma"],
    img: Img7,
  },
  {
    name: "Tomás Cerqueira",
    rate: 80,
    role: "Tech Lead",
    location: "Floripa, Brazil",
    techs: ["Javascript", "Agile", "Scrum"],
    img: Img8,
  },
]
