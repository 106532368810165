import React from "react"
import { x } from "@xstyled/styled-components"
import Container from "@atoms/Container"
import TextImage from "@atoms/TextImage"
import Matches from "@assets/images/home/matches.png"
import Tell from "@assets/images/home/tell.png"
import Hire from "@assets/images/home/hire.png"
import { Title } from "@atoms/Typography/index"
import idgen from "@utils/idgen"

const HowItWorks = () => {
  const items = [
    {
      title: `1.
      Tell us what you need`,
      paragraphs: `Building a team from scratch? Need to deliver a digital project but don’t have the team to do it? 
        Add capacity to your existing tech team? Just tell us the basics and our experts will take it from there.`,
      image: Tell,
      contentPosition: "1",
    },
    {
      title: `2.
      Get matches in 48 hours`,
      paragraphs: `Building a team from scratch? Need to deliver a digital project but don’t have the team to do it? 
        Add capacity to your existing tech team? Just tell us the basics and our experts will take it from there.`,
      image: Matches,
      contentPosition: "2",
    },
    {
      title: `3.
      Hire`,
      paragraphs: `Building a team from scratch? Need to deliver a digital project but don’t have the team to do it? 
      Add capacity to your existing tech team? Just tell us the basics and our experts will take it from there.`,
      image: Hire,
      contentPosition: "1",
    },
  ]
  return (
    <x.section bg="black">
      <Title
        id="how-it-works"
        as="h2"
        variant="h2"
        textAlign="center"
        px={{ lg: 150, xl: 250 }}
        fontSize={{ md: 40, lg: 52 }}
        fontWeight="bold"
        mb={{ _: 62, lg: 57, xl: 63 }}
        color="white"
        pt={{ _: 50, md: 80, lg: 117.82, xl: 117.82 }}
      >
        How it works
      </Title>
      {items.map(({ title, paragraphs, image, contentPosition }) => (
        <x.section position="relative" key={idgen()}>
          <Container>
            <TextImage
              title={title}
              paragraphs={paragraphs}
              image={image}
              order={contentPosition}
              color="white"
            />
          </Container>
        </x.section>
      ))}
      <x.div pb={{ _: 100 }} />
    </x.section>
  )
}

export default HowItWorks
