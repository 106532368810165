import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { x } from "@xstyled/styled-components"

import Container from "@atoms/Container"

import idgen from "@utils/idgen"
import { Text, Title } from "@atoms/Typography/index"

const HowItWorks = () => {
  const data = useStaticQuery(graphql`
    query {
      dashboard: file(name: { eq: "squad-dashboard" }) {
        childImageSharp {
          gatsbyImageData(width: 1280)
        }
      }
      billing: file(name: { eq: "squad-billing" }) {
        childImageSharp {
          gatsbyImageData(width: 1280)
        }
      }
      squad: file(name: { eq: "squad-squad" }) {
        childImageSharp {
          gatsbyImageData(width: 1280)
        }
      }
    }
  `)

  const items = [
    {
      title: `Hire your team &
      deliver your projects`,
      paragraphs: `Find people quickly, add them to your team and start work. Get visibility over costs and where time is being spent. Build and manage multiple teams at once.`,
      image: getImage(data.dashboard),
    },
    {
      title: `Per-hour billing, only pay for time worked`,
      paragraphs: `Don’t pay for wasted time when your team is idle or blocked. Track costs to make sure you don’t overspend. View and export audit reports easily.`,
      image: getImage(data.billing),
    },
    {
      title: `Quick & simple payments - with zero committment`,
      paragraphs: `Simple digital payments with no need to request individual invoices or bank details from your team. Pause work, change your team or cancel with no notice required.`,
      image: getImage(data.squad),
    },
  ]
  return (
    <x.section>
      {items.map(({ title, paragraphs, image }) => (
        <x.section key={idgen()}>
          <Container>
            <x.div
              row
              alignItems="center"
              my={{ _: 100, lg: 200 }}
              justifyContent="space-between"
            >
              <x.div
                col={{ _: 1, md: 1 / 2 }}
                justifyContent="center"
                alignItems="center"
                //
              >
                <x.div
                  maxWidth={{
                    _: "full",
                    lg: 425,
                    xl: 460,
                  }}
                  margin={{ _: 20, lg: 0, xl: 0 }}
                >
                  {title && (
                    <Title as="h3" variant="h3" mb={{ _: 30, lg: 22, xl: 30 }}>
                      {title}
                    </Title>
                  )}
                  {paragraphs && (
                    <Text variant="sm" lineHeight="1.5">
                      {paragraphs}
                    </Text>
                  )}
                </x.div>
              </x.div>

              <x.div col={{ _: 1, md: 1 / 2 }} mb={{ _: 25, lg: 0, xl: 0 }}>
                <x.div
                  transform
                  translateX={{
                    md: "10%",
                    lg: "40%",
                  }}
                  scale={{
                    lg: 1.2,
                  }}
                  // display="flex"
                  // flexWrap="wrap"
                  // flexDirection="row-reverse"
                  // justifyContent={{ _: "center", lg: "right", xl: "right" }}
                  // alignItems={{ _: "center", lg: "right", xl: "right" }}
                >
                  <GatsbyImage image={image} alt={data.title} />
                </x.div>
              </x.div>
            </x.div>
          </Container>
        </x.section>
      ))}
      <x.div pb={{ _: 100 }} />
    </x.section>
  )
}

export default HowItWorks
