import React from "react"
import PropTypes from "prop-types"

import { useForm } from "react-hook-form"
import { string, object } from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import config from "@data/SiteConfig"
import InputField from "@atoms/InputField"
import MailChimpForm from "@atoms/MailChimpForm"

const schema = object().shape({
  EMAIL: string().email().required(),
})

const MailChimpRegister = ({ label, buttonLabel, variant, ...props }) => {
  const methods = useForm({
    resolver: yupResolver(schema),
  })
  return (
    <MailChimpForm action={config.mailChimpAction} methods={methods} {...props}>
      {label}
      <InputField
        mt={15}
        inputProps={{
          id: "EMAIL",
          name: "EMAIL",
          placeholder: "Your email address",
          type: "email",
        }}
        actionProps={{
          px: { _: "25px", lg: "30px" },
          children: buttonLabel || "Subscribe",
          type: "submit",
          variant,
        }}
      />
    </MailChimpForm>
  )
}

MailChimpRegister.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.element,
}

MailChimpRegister.defaultProps = {
  variant: "primary",
  label: null,
}

export default MailChimpRegister
