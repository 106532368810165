import React from "react"
import styled, { th, css } from "@xstyled/styled-components"
import { PL } from "@atoms/Typography"

const Input = ({ register, error, ...props }) => {
  return <S.Input ref={register} error={error} {...props} />
}

const S = {}

S.Input = styled.inputBox`
  ${PL};
  line-height: 1.4;
  border: none;
  border-radius: 100;
  box-sizing: border-box;
  outline: none;
  appearance: none;
  box-shadow: ${th.color("neutral.grey.300")} 0px 0px 0px 1px;
  color: ${th.color("neutral.grey.400")};
  height: 58;
  width: ${th.size("full")};
  padding-left: 24;
  font-variant-ligatures: none;

  &:disabled {
    cursor: not-allowed;
    background-color: ${th("colors.neutral.grey.100")};
    box-shadow: rgba(43, 54, 117, 0.1) 0px 0px 0px 1px;
    border-color: rgba(43, 54, 117, 0.1);
    color: ${th("colors.neutral.grey.300")};

    &:hover {
      border-color: rgba(43, 54, 117, 0.1);
      color: ${th("colors.neutral.grey.300")};
    }
  }

  :focus {
    box-shadow: ${th.color("brand.imabi.blue")} 0px 0px 0px 1px;
    color: ${th.color("brand.imabi.blue")};
  }

  :focus::placeholder {
    color: ${th.color("brand.imabi.blue")};
  }

  ::placeholder {
    line-height: 1.4;
    color: ${th.color("neutral.grey.400")};
  }

  ${({ error }) =>
    error &&
    css`
      box-shadow: ${th.color("neutral.red")} 0px 0px 0px 1px;
      :focus {
        box-shadow: ${th.color("neutral.red")} 0px 0px 0px 1px;
      }
    `}
`

export default Input
