import React from "react"
import styled, { x, breakpoints, css } from "@xstyled/styled-components"

import Image from "@atoms/Image"
import { StaticImage } from "gatsby-plugin-image"

// import HelloWorld from '@assets/images/home/home-hero.png'
// import HelloWorldMd from '@assets/images/home/home-hero-md.png'
// import HelloWorldCircle from '@assets/images/home/hello-world-circle.png'

export default () => (
  <x.div
    w="100%"
    transform
    translateX={{
      _: "40%",
      md: "10%",
    }}
    mb={{ _: "50px" }}
  >
    <x.div
      w={{ _: "370px", md: "613px" }}
      h={{ _: "211px", md: "500px" }}
      position="relative"
    >
      <StaticImage
        src="./home-hero.png"
        position="fixed"
        placeholder="none"
        alt="SQUAD Laptop"
      />
      <S.Outer>
        <S.Wrap position="relative">
          <S.RotatingImage>
            <StaticImage
              placeholder="none"
              src="./hello-world-circle.png"
              alt="SQUAD Hello World"
            />
          </S.RotatingImage>
          <S.PeaceWrap>
            <StaticImage
              placeholder="none"
              src="./peace.png"
              alt="SQUAD Peace"
            />
          </S.PeaceWrap>
        </S.Wrap>
      </S.Outer>
    </x.div>
  </x.div>
)

const S = {}

S.Outer = styled.div`
  position: absolute;
  top: 30px;
  left: 20%;
  bottom: 0;
  ${breakpoints({
    _: css``,
    lg: css`
      top: 20px;
      left: 15%;
    `,
  })}
`

S.Wrap = styled.div`
  width: 150px;
  height: 150px;
  ${breakpoints({
    md: css`
      width: 200px;
      height: 200px;
    `,
    lg: css`
      width: 300px;
      height: 300px;
    `,
  })}
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

S.PeaceWrap = styled.div`
  width: 100px;
  ${breakpoints({
    md: css`
      width: 120px;
    `,
    lg: css`
      width: 150px;
    `,
  })}
`

S.RotatingImage = styled.div`
  /* width: 300px;
  height: 300px; */

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  animation: rotation 20s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

S.RotatingImageMd = styled(Image)`
  width: 90%;
  position: absolute;
  animation: rotation 10s infinite linear;
  right: -75px;
  top: 15px;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`
