import React from "react"
import Img from "gatsby-image"

const Image = ({ image, alt, half, ...props }) => {
  const { childImageSharp, url } = image
  if (!!url && typeof url === "string") {
    return <img src={url} alt={alt} {...props} />
  }

  if (!!image && typeof image === "string") {
    return <img src={image} alt={alt} {...props} />
  }

  if (childImageSharp) {
    const { fluid, halfFluid } = childImageSharp
    return <Img fluid={half ? halfFluid : fluid} alt={alt} {...props} />
  }

  return null
}

export default Image
