import React, { useState } from "react"
import slugify from "slugify"
import { x } from "@xstyled/styled-components"
import { Text } from "@atoms/Typography/index"

const QuestionAnswer = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { title, paragraph } = data

  return (
    <x.div
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
      pb={{ _: 30, md: 40, xl: 47 }}
      cursor="pointer"
      onClick={() => setIsOpen(!isOpen)}
      w={{ _: "auto", md: "700px" }}
      id={slugify(title)}
    >
      <x.div row display="flex" flexWrap="none">
        <x.div fontSize={25} mr={5}>
          {isOpen ? "-" : "+"}
        </x.div>
        <Text variant="lg" as="h3" alignSelf="center" itemProp="name">
          {title}
        </Text>
      </x.div>
      <x.div
        ml={15}
        display={isOpen ? "block" : "none"}
        borderLeft="1px solid black"
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <Text ml={15} mt={10} variant="lg" itemProp="text">
          {paragraph}
        </Text>
      </x.div>
    </x.div>
  )
}

export default QuestionAnswer
