import React, { useState, useCallback } from "react"
import { FiArrowDown } from "react-icons/fi"

import styled, { th, x } from "@xstyled/styled-components"

import Container from "@atoms/Container"
import { Text, Title } from "@atoms/Typography/index"
import devs from "@components/data/devs"
import DevCard from "@molecules/DevCard/index"
import Button from "@atoms/Button/index"
import Card from "@atoms/Card/index"

import ToastContainer from "@atoms/Toast/ToastContainer"

function Calculator() {
  const [total, setTotal] = useState(0)

  const sum = useCallback(
    (value) => {
      setTotal(total + value)
    },
    [total]
  )

  const sub = useCallback(
    (value) => {
      setTotal(total - value)
    },
    [total]
  )

  return (
    <S.Section id="calculator">
      <S.Container>
        <Title
          id="calculator"
          as="h2"
          variant="h2"
          textAlign="center"
          fontSize={{ md: 40, lg: 52 }}
          fontWeight="bold"
          mb={{ _: 20 }}
        >
          Vetted talent, <br /> available immediately
        </Title>
        <Text
          textAlign="center"
          fontSize={{ _: 18, lg: 20 }}
          variant="sm"
          lineHeight="1.5"
          mb={{ _: 30 }}
        >
          Hire one person, a small team or a full-service digital product
          development SQUAD.
        </Text>
        <x.div
          display="flex"
          flexDirection={{ _: "column", md: "row" }}
          alignItems="center"
        >
          <Text fontSize={{ _: 18, lg: 20 }} pr={{ sm: 18 }}>
            Team cost:
          </Text>
          <x.div>
            <Text
              fontSize={{ _: 18, lg: 20 }}
              pr={{ sm: 18 }}
              pb={{ _: 10, sm: 0 }}
            >
              <S.Value>${total}</S.Value> per day
            </Text>
          </x.div>
          <FiArrowDown size="36" color="black" />
        </x.div>

        {total !== 0 && (
          <x.div display={{ _: "block", md: "none" }}>
            <ToastContainer>
              <x.div display="flex" flexDirection="row" alignItems="center">
                <Text
                  color="white"
                  fontSize={{ _: 18, lg: 20 }}
                  pr={{ sm: 18 }}
                >
                  Team cost:
                </Text>
                <Text
                  color="white"
                  fontSize={{ _: 18, lg: 20 }}
                  pl={{ sm: 5 }}
                  pb={{ _: 10, sm: 0 }}
                >
                  <S.Value>${total}</S.Value> per day
                </Text>
              </x.div>
            </ToastContainer>
          </x.div>
        )}

        <x.div
          display="flex"
          flexDirection="row"
          mt={{ _: 70 }}
          flexWrap="wrap"
        >
          {devs.map((dev, id) => (
            <DevCard id={id} key={dev.name} dev={dev} sum={sum} sub={sub} />
          ))}
          <x.div padding="15px" col={{ xs: 1, lg: 1 / 2, xl: 1 / 3 }}>
            <Card
              height="125"
              margin="0"
              // width="350px"
              borderColor="#C5C5C5"
              borderStyle="dashed"
              borderWidth="2px"
              background="#E0E0E0"
            >
              <x.div
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                flex="1"
                p="10px"
              >
                <Text
                  as="p"
                  textAlign="center"
                  fontSize={{ _: 24 }}
                  fontWeight="bold"
                  color="#868686"
                >
                  Add Team Member
                </Text>
                <Button
                  buttonMode="LINK"
                  height="25px"
                  w="225px"
                  h="35px"
                  marginTop="15px"
                  className="drift-open-chat"
                >
                  + Add
                </Button>
              </x.div>
            </Card>
          </x.div>
        </x.div>
        <x.div
          mt={{ _: 100 }}
          mb={{ _: "s15", lg: 0 }}
          mr={{ lg: "s20" }}
          textAlign="center"
        >
          <Button buttonMode="LINK" w={280} className="drift-open-chat">
            Hire your team
          </Button>
        </x.div>
      </S.Container>
    </S.Section>
  )
}

const S = {}

S.Section = styled.section`
  padding-top: 110;
  padding-bottom: 110;
`

S.Container = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

S.Value = styled.span`
  font-family: ${th.font("0")};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;

  background-image: linear-gradient(360deg, #f49ae0 0%, #f49ae0 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
`

S.Photo = styled.img`
  width: 68px;
  border-radius: 50%;
`

export default Calculator
