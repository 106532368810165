import React from "react"
import styled, { x, breakpoints, css } from "@xstyled/styled-components"
import Button from "@atoms/Button"
import idgen from "@utils/idgen"
import { isEmpty } from "lodash"

const ButtonList = ({ items }) => {
  return (
    <x.div display="flex" flexDirection={{ _: "column", lg: "row" }}>
      {!isEmpty(items) &&
        items.map(({ label, buttonMode, buttonTo, variant }) => (
          <S.ButtonDiv mt={{ _: 32 }} mb={{ _: 16, lg: 0 }} key={idgen()}>
            <Button
              buttonMode={buttonMode}
              to={buttonTo}
              variant={variant}
              fullWidthButton
            >
              {label}
            </Button>
          </S.ButtonDiv>
        ))}
    </x.div>
  )
}

const S = {}

S.ButtonDiv = styled.box`
  &:last-of-type {
    margin-bottom: 0;
    margin-right: 0;
  }
  ${breakpoints({
    lg: css`
      margin-right: 20;
    `,
  })}
`

export default ButtonList
