import styled, { breakpoints, css } from "@xstyled/styled-components"

const NavigationSpacer = styled.div`
  ${breakpoints({
    _: css`
      margin-top: 70px;
    `,
    sm: css`
      margin-bottom: 101px;
    `,
  })}
`

export default NavigationSpacer
