import React from "react"
import styled, { th, breakpoints, css } from "@xstyled/styled-components"
import { Helmet } from "react-helmet"
import Hero from "@organisms/Home/Hero"
import HowItWorks from "@organisms/Home/HowItWorks"
import Pricing from "@organisms/Home/Pricing"
import FAQ from "@organisms/Home/FAQ"
import Cta from "@organisms/Home/Cta"
import Features from "@organisms/Home/Features"
import Calculator from "@organisms/Home/Calculator"
import Advantages from "@organisms/Home/Advantages"

function HomePage() {
  return (
    <>
      <Helmet title="SQUAD - Hire the digital team you need" />
      <S.Page>
        <Hero />
        <Features />
        <Advantages />
        <HowItWorks />
        <Calculator />
        <Pricing />
        <FAQ />
        <Cta />
      </S.Page>
    </>
  )
}

const S = {}

S.Page = styled.div`
  background-color: ${th("colors.grey")};
  ${breakpoints({
    _: css`
      padding-top: 30;
    `,
    lg: css`
      padding-top: 75;
    `,
  })}
`

export default HomePage
