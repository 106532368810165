import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  border-radius: ${(props) => props.borderRadius};
  justify-content: space-between;
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};
  border-style: ${(props) => props.borderStyle};
  border-color: ${(props) => props.borderColor};
  border-width: ${(props) => props.borderWidth};
  align-items: center;

  ${({ width }) => width && `width: ${width};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
`

const Card = ({
  children,
  color = "black",
  background = "white",
  height = "125px",
  padding = "0px",
  borderRadius = "10px",
  margin = "40px",
  borderStyle = "solid",
  borderColor = "black",
  borderWidth = "0px",
  width,
  textAlign,
}) => (
  <Container
    borderRadius={borderRadius}
    height={height}
    color={color}
    padding={padding}
    background={background}
    margin={margin}
    width={width}
    textAlign={textAlign}
    borderStyle={borderStyle}
    borderColor={borderColor}
    borderWidth={borderWidth}
  >
    {children}
  </Container>
)

export default Card
