/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import styled, { x, breakpoints, css } from "@xstyled/styled-components"
import idgen from "@utils/idgen"
import { Title } from "@atoms/Typography"
import FeatureCallOutItem from "@molecules/FeatureCallOutItem"

const FeatureCallOut = ({
  title,
  titleItemsProps,
  paragraphsProps,
  items,
  ...props
}) => {
  return (
    <x.div {...props}>
      {title && (
        <x.div>
          {title && (
            <Title
              as="h2"
              variant="h2"
              textAlign={{ _: "initial", md: "center" }}
              px={{ lg: 150, xl: 200 }}
              fontSize={{ md: 40, lg: 52 }}
              fontWeight="bold"
              mb={{ _: 62, lg: 57, xl: 63 }}
            >
              {title}
            </Title>
          )}
        </x.div>
      )}
      <S.FeatureCallOutContainer row mx="-15px">
        {!isEmpty(items) &&
          items.map(({ title: featureTitle, paragraphs, image }) => (
            <FeatureCallOutItem
              key={idgen()}
              title={featureTitle}
              titleItemsProps={titleItemsProps}
              paragraphs={paragraphs}
              paragraphsProps={paragraphsProps}
              image={image}
              display="flex"
              justifyContent="center"
              col={{ _: 1, md: 1 / 3 }}
              px="15px"
              mb={{ _: 62, lg: 0 }}
            />
          ))}
      </S.FeatureCallOutContainer>
    </x.div>
  )
}

const S = {}

S.FeatureCallOutContainer = styled.divBox`
  ${breakpoints({
    _: css`
      &:last-child {
        margin-bottom: 0;
      }
    `,
  })}
`

FeatureCallOut.propTypes = {
  title: PropTypes.string,
  titleItemsProps: PropTypes.object,
  paragraphsProps: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paragraphs: PropTypes.string,
      image: PropTypes.string,
    })
  ),
}

FeatureCallOut.defaultProps = {
  title: null,
  titleItemsProps: {},
  paragraphsProps: {},
  items: null,
}

export default FeatureCallOut
