import React, { useState, useEffect } from "react"
import styled, { css } from "@xstyled/styled-components"
import { breakpoints, th } from "@xstyled/system"
import { transparentize } from "polished"

import Toast from "./index"

const ToastContainer = ({ children, ...props }) => {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    setVisible(true)
    const timer1 = setTimeout(() => {
      setVisible(false)
    }, 5000)

    return () => {
      clearTimeout(timer1)
    }
  }, [children])
  return <Toast {...props} visible={visible} children={children} />
}

const S = {}

export default ToastContainer
