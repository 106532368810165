import React from "react"
import { x } from "@xstyled/styled-components"
import { isEmpty, get } from "lodash"
import { useFormContext } from "react-hook-form"
import Input from "@atoms/Input"
import InputAction from "@atoms/InputAction"
import ErrorMessageField from "@atoms/ErrorMessageField"
import getErrorsMessage from "./getErrorsMessage"

const InputField = ({ inputProps, actionProps, ...props }) => {
  const { id, name } = inputProps
  const { register, errors, formState } = useFormContext()

  return (
    <x.div {...props}>
      {isEmpty(actionProps) ? (
        <Input
          id={id}
          name={name}
          register={register}
          error={get(errors, name)}
          {...inputProps}
        />
      ) : (
        <InputAction
          id={id}
          name={name}
          register={register}
          error={get(errors, name)}
          inputProps={{
            ...inputProps,
            disabled: formState.isSubmitting,
          }}
          actionProps={{
            ...actionProps,
            disabled: formState.isSubmitting,
          }}
        />
      )}

      <ErrorMessageField
        errors={getErrorsMessage(errors)}
        name={name}
        mt="s5"
      />
    </x.div>
  )
}

export default InputField
